.footerBg {
  background: #344A9B;
  color: white;
}

.logoImg{
  width: 30px;
  margin: 5px 10px 0 10px;
  object-fit: contain;
  object-position: center;
}

.footerFont{
  color: white;
  font-size: 14px;
  margin-top: 10px;
}