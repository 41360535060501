.headerPy {
  padding-top: 1.8125rem;
  padding-bottom: 1.8125rem;
}

.headerPy2 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.hwObQA {
  z-index: 20000;
  transition: all 0.4s ease 0s;
  border: 1px solid rgb(255, 255, 255);
  background: rgba(0, 0, 0, 0.3);
  font-size: 15px;
  border-radius: 30px;
  cursor: pointer;
  user-select: none;
  color: white;
  padding: 10px 30px 10px 30px;
}

.hwObQA:hover {
  opacity: 0.6;
}

.logoImg {
  width: 35px;
  margin: 0px auto;
}