.mainScreenHeight {
  height: calc(100vh - 100px);
}

.headerMt {
  margin-top: 6.25rem;
}

.mt335 {
  padding-top: 20.9375rem;
}

.angoFont {
  font-size: 4rem;
  line-height: 4.85rem;
}

.homeTitleFont {
  font-size: 2.5rem;
  line-height: 3rem;
  font-weight: 700;
}

.homeContentFont {
  font-size: 1.5625rem;
  line-height: 1.875rem;
  font-weight: 500;
}

.homeButton {
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 600;
  @apply w-36 h-12 mt-14 bg-gray-900 text-white rounded;
}

.getStartedWH {
  width: 14.375rem;
  height: 4rem;
}

.seeNowWH {
  width: 11.875rem;
  height: 4rem;
}

.homeBgTop {
  margin-top: -60px;
}

.zoomBtnLeft {
  background: #ff8d00;
  border-color: #ff8d00;
  color: white;
  font-weight: 600;
  padding: 5px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color .5s;
  width: 90px;
  display: inline-block;
}

.zoomBtnRight {
  background: #ff8d00;
  border-color: #ff8d00;
  color: white;
  font-weight: 600;
  padding: 5px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color .5s;
  width: 90px;
  display: inline-block;
}

.homeOtherRoomTitle {
  font-weight: 800;
  font-size: 2rem;
  padding: 10px;
  margin-top: 20px;
}