@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-color: #59131300 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

#root {
  @apply w-full h-screen;
}

.modal-content {
  width: 26.25rem !important;
}

.main-container {
  width: 100%;
  overflow: hidden;
  font-family: Aeonik;
  max-width: 1000px;
  margin: 0px auto;
  padding: 8px 32px;
  user-select: none;
  background: rgba(22, 66, 61, 0);
  color: rgb(255, 255, 255);
  z-index: 2000;
}

.textFooter {
  font-size: 1rem;
  font-weight: 700;
  line-height: 2.5rem;
  color: white;
}

.footer {
  padding: 20px;
}

.img {
  object-fit: contain;
  object-position: center;
}

#unity-container {
  width: 100%;
  height: 100%;
}

#unity-container :hover {
  width: 100%;
  height: 100%;
}

#unity-canvas {
  width: 100%;
  height: 100%;
  background: #000000;
}

#loading-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#unity-loading-bar {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#unity-logo {
  text-align: center;
}

#unity-logo img {
  max-width: 80%;
}

#unity-progress-bar-empty {
  width: 80%;
  height: 24px;
  margin: 10px 20px 20px 10px;
  text-align: left;
  border: 1px solid white;
  padding: 2px;
}

#unity-progress-bar-full {
  width: 0%;
  height: 100%;
  background: white;
}

.light #unity-progress-bar-empty {
  border-color: black;
}

.light #unity-progress-bar-full {
  background: black;
}

#unity-fullscreen-button {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 38px;
  height: 38px;
  //background: url('fullscreen-button.png') no-repeat center;
  background-size: contain;
}

.spinner,
.spinner:after {
  border-radius: 50%;
  width: 5em;
  height: 5em;
}

.spinner {
  margin: 10px;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #ffffff;
  transform: translateZ(0);
  animation: spinner-spin 1.1s infinite linear;
}

@keyframes spinner-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.bgMain {
  background: #ff8d00;
}

.mainBtn {
  min-width: 180px !important;
  background: #ff8d00 !important;
  border-color: #ff8d00 !important;
  color: white !important;
  margin: 0px 10px !important;
  font-weight: 600 !important;
}

.mainBtnA {
  min-width: 180px !important;
  background: white !important;
  border-color: white !important;
  color: #ff8d00 !important;
  margin: 0 10px !important;
  font-weight: 600 !important;
}

.backgroundRed {
  background: darkred;
}

.input-recika {
  background: black !important;
  color: white !important;
  border-color: white !important;
}

.minHeight {
  padding-top: 60px;
  min-height: calc(100vh - 120px);
}

.nft-3d-div {
  background: linear-gradient(to left top, black 84%, #ec8201 70%);
  height: 100%;
  width: 100%;
}

.nft-3d-base-layout {
  width: calc(100vw);
  min-height: calc(75vw);
  height: 100%;
  padding: 2% 4% 0 4%;
}

.nft-3d-image {
  position: absolute;
  top: 20%;
  left: -10%;
  margin-right: 5%;
}

.nft-3d-x {
  position: absolute;
  right: -15px;
  top: -13px;
}

.ant-switch {
  background: #ffffff00 !important;
  border: 1px solid white !important;
  min-width: 50px !important;
}

.ant-switch-handle:before {
  background: #ec8201 !important;
}

.ant-switch-checked:focus {
  box-shadow: none !important;
}

.menu-hover:hover{
  background: #fafafa42;
  border-radius: 10px;
}

.menu-wallet-small{
  background: #fafafa42;
  padding: 5px;
  border-radius: 10px;
  margin-top: 5px;
}

.t1{
  font-size: 0.9rem;
  line-height: 1.25rem;
}