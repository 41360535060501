.pop{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
}

.canvasDiv{
  //border: 1px solid red;
  cursor: default;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.canvasDivBtnDiv{
  margin-top: 10px;
  text-align: center;
  position: absolute;
  display: flex;
  width: 100%;
  top: 30%;
  left: 25%;
}
.canvasangoDivBtnDiv{
  margin-top: 10px;
  text-align: center;
  position: absolute;
  display: flex;
  width: 100%;
  top: 40%;
  left: 10%;
}
.canvasXbtn{
  width: 100%;
  top: 14%;
  right: 21%;
  z-index: 2;
  position: absolute;
}